import { defaultFormsLocale } from 'constants/intl';
import Validator from 'formiojs/components/Validator';
import { FormApi } from '../api/FormApi';
import FormioSidebarStore from '../stores/FormioSidebarStore';
import { flkValidate } from '../utils/flk';

export type ComponentWithValidationMessage = {
    component: any;
    message: string;
};

export type ValidateReadonlyPageProps = {
    setFormErrors: (errors: ComponentWithValidationMessage[]) => void;
    setShowValidation?: (isShowValidation: boolean) => void;
    onSuccess?: () => Promise<void>;
    formApi?: FormApi;
    formioSidebarStore?: FormioSidebarStore;
    formName?: string;
    withoutSignatureValidate?: boolean;
};

export function validateComponentIfVisible(
    component: any,
    data: any,
    withoutSignatureValidate?: boolean,
): ComponentWithValidationMessage[] {
    const isComponentVisible = component.checkConditions();

    return !isComponentVisible ? [] : validateComponent(component, data, withoutSignatureValidate);
}

const signatureValidator = 'signatureRequired';

function validateComponent(
    component: any,
    data: any,
    withoutSignatureValidate?: boolean,
): ComponentWithValidationMessage[] {
    // Улучшила тем, что result привела к такому же виду, как в валидации через submit.
    // За счет этого ошибки можно выводить в правом меню навигации.
    const result: ComponentWithValidationMessage[] = [];
    let currentComponentCheck: string;

    if (withoutSignatureValidate) {
        const validators = component.validators;
        const validatorsWithoutSignature = validators.map((validator: string) => validator !== signatureValidator);
        component.validators = validatorsWithoutSignature;

        currentComponentCheck = Validator.check(component, data);

        component.validators = validators;
    } else {
        currentComponentCheck = Validator.check(component, data);
    }

    if (currentComponentCheck) {
        result.push({
            component: component.component,
            message: currentComponentCheck,
        });
    }

    if (typeof component.getComponents === 'function') {
        return component
            .getComponents()
            .reduce(
                (arr: any[], comp: any) => arr.concat(validateComponentIfVisible(comp, data, withoutSignatureValidate)),
                result,
            );
    } else {
        return result;
    }
}

export const validateEditPage = (
    formApi?: FormApi,
    formioSidebarStore?: FormioSidebarStore,
    formName?: string,
): Promise<void> => {
    if (formApi) {
        return formApi
            .submit(true)
            .then(() => {
                formioSidebarStore && formName && formioSidebarStore.updateSidebarErrors(formName);
                return Promise.resolve();
            })
            .catch((errors: any[]) => {
                formioSidebarStore && formName && formioSidebarStore.updateSidebarErrors(formName, errors);
                console.log('validation errors:', errors);
                return Promise.reject(errors);
            });
    } else {
        return Promise.reject(['formApi is undefined']);
    }
};

export const validateReadonlyPage = (props: ValidateReadonlyPageProps): Promise<void> => {
    const {
        setFormErrors,
        setShowValidation,
        onSuccess,
        formApi,
        formioSidebarStore,
        formName,
        withoutSignatureValidate,
    } = props;

    // Для валидации заявки в текстовом виде пришлось добавить отдельный метод,
    // т.к. валидация через submit использует отрендеренные formio компоненты
    if (formApi) {
        const formData = formApi.getSubmission();
        if (!formData[defaultFormsLocale]) {
            return Promise.reject('Отсутствует submission. Надо заполнить форму');
        }

        const errors = validateComponentIfVisible(
            formApi.form(),
            formData[defaultFormsLocale].data,
            withoutSignatureValidate,
        );
        if (!errors.length) {
            formioSidebarStore && formName && formioSidebarStore.updateSidebarErrors(formName);
            return onSuccess ? onSuccess() : Promise.resolve();
        } else {
            setFormErrors(errors);
            setShowValidation && setShowValidation(true);
            formioSidebarStore && formName && formioSidebarStore.updateSidebarErrors(formName, errors);
            console.log('validation errors:', errors);
            return Promise.reject(errors);
        }
    } else {
        return Promise.reject(['formApi is undefined']);
    }
};
