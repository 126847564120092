import { AppTheme } from '../../types/theme';
import { css } from 'styled-components';
import textEllipsis from '../mixins/textEllipsis';

export default function (theme: AppTheme) {
    const sassVars = theme.variables.sass as any;
    const select = theme.variables.formio.select;
    const palette = theme.variables.palette;
    return css`
        .formio-component-select,
        .formio-component-expertiseScoreSelect,
        .formio-component-tags {
            .formio-choices,
            .choices {
                margin-bottom: 0;
                &[data-type='select-one'],
                &[data-type='select-multiple'],
                &[data-type='text'] {
                    .choices__input {
                        background-color: ${sassVars.selectChoicesBackgroundColor};
                    }

                    .choices__placeholder {
                        color: ${sassVars.selectChoicesPlaceholderColor};
                        opacity: ${sassVars.selectChoicesPlaceholderOpacity};
                    }
                }

                &[data-type='select-one'],
                &[data-type='select-multiple'] {
                    &.is-disabled {
                        .form-control {
                            background-color: ${sassVars.inputDisabledBackgroundColor};
                            color: ${sassVars.inputDisabledColor};
                            border-color: ${sassVars.inputDisabledBorderColor};
                            opacity: 1;
                            cursor: not-allowed;

                            .choices__placeholder {
                                color: ${sassVars.inputDisabledColor};
                            }

                            .choices__item {
                                color: ${sassVars.inputDisabledColor};
                            }
                        }

                        &:after {
                            color: ${sassVars.inputDisabledIconColor};
                        }
                    }

                    &:after {
                        content: '\\e90e';
                        height: ${sassVars.selectIconArrowHeight};
                        width: ${sassVars.selectIconArrowWidth};
                        font-family: ${sassVars.fontFamilyGlyphIcons};
                        font-size: ${sassVars.selectIconArrowSize};
                        color: ${theme.palette.primary.main};
                        line-height: ${sassVars.selectIconArrowLineHeight};
                        border: none;
                        position: absolute;
                        right: ${sassVars.selectIconArrowRight};
                        top: 50%;
                        margin-top: 0;
                        pointer-events: none;
                        transform: translateY(-50%);
                        transition: ${sassVars.selectIconArrowTransitionColor};
                    }

                    &:hover:after {
                        color: ${sassVars.selectIconArrowHoverColor};
                    }

                    &.is-open:after {
                        border: none;
                        margin-top: 0;
                        color: ${sassVars.selectOpenIconColor};
                        transform: rotate(180deg) translateY(50%);
                    }
                }

                &[data-type='select-one'] {
                    .choices__item {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        padding-right: ${sassVars.selectSingleChoicesPaddingRight};
                    }

                    .choices__button {
                        background-image: none;
                        background-size: 0;
                        padding: ${sassVars.selectChoicesButtonPadding};
                        position: absolute;
                        top: 50%;
                        right: ${sassVars.selectChoicesButtonRight};
                        transform: translateY(-50%);
                        margin-top: 0;
                        margin-right: 0;
                        height: ${sassVars.selectChoicesButtonHeight};
                        width: ${sassVars.selectChoicesButtonWidth};
                        border-radius: ${sassVars.selectChoicesButtonBorderRadius};
                        opacity: ${sassVars.selectChoicesButtonOpacity};

                        &:hover {
                            opacity: ${sassVars.selectChoicesButtonOpacity};

                            &:after {
                                color: ${sassVars.selectIconArrowHoverColor};
                            }
                        }
                    }
                }

                .choices__input.is-hidden {
                    display: none;
                }

                &[data-type='select-multiple'],
                &[data-type='text'] {
                    .form-control,
                    .choices__inner {
                        height: auto;
                        padding-bottom: ${sassVars.selectBorderWidth};

                        .choices__list {
                            display: inline-block;
                            vertical-align: top;
                            max-width: 100%;
                        }

                        .choices__input {
                            font-size: ${sassVars.selectInputFontSize};
                            line-height: ${sassVars.selectInputLineHeight};
                            color: ${sassVars.selectInputColor};
                        }

                        .choices__item {
                            display: inline-flex;
                            font-size: ${select.selectChipMultiple.fontSize}px;
                            line-height: ${select.selectChipMultiple.lineHeight}px;
                            font-weight: ${sassVars.selectMultipleChoicesFontWeight};
                            background-color: ${palette.hoverInLists};
                            border: none;
                            color: ${palette.accentContrast};
                            margin: ${sassVars.selectMultipleChoicesMargin};
                            border-radius: ${select.selectChipMultiple.borderRadius}px;
                            padding: ${sassVars.selectMultipleChoicesPadding};
                            height: ${select.selectChipMultiple.height}px;
                            align-items: center;
                            vertical-align: initial;
                            cursor: default;
                            transition: ${select.selectChipMultiple.transition};
                            max-width: calc(
                                100% - ${sassVars.selectIconArrowWidth} - ${sassVars.selectMultipleChoicesMarginRight}
                            );

                            &:hover {
                                background-color: ${palette.main};
                                color: ${palette.mainContrast};

                                .choices__button:after {
                                    color: ${palette.mainContrast};
                                }
                            }

                            span {
                                ${() => textEllipsis()}
                            }
                        }

                        .choices__button {
                            position: relative;
                            display: inline-block;
                            width: ${sassVars.selectChoicesButtonWidth};
                            height: ${sassVars.selectChoicesButtonHeight};
                            margin: ${sassVars.selectChoicesButtonMargin};
                            padding: ${sassVars.selectChoicesButtonPadding};
                            border-left: none;
                            background-image: none;
                            background-size: 0;
                            opacity: ${sassVars.selectChoicesButtonOpacity};
                            border-radius: ${sassVars.selectChoicesButtonBorderRadius};
                            pointer-events: auto;
                        }
                    }
                }

                &[data-type='text'] {
                    margin: 0;

                    .choices__inner {
                        .choices__input.form-control {
                            margin-bottom: ${sassVars.selectMultipleChoicesMarginBottom};
                        }

                        .choices__item {
                            ${() => textEllipsis()};

                            padding-right: ${sassVars.selectChoicesButtonWidth};

                            &:only-child {
                                padding-right: ${() => `calc(${sassVars.selectChoicesButtonWidth} + 10px)`}; // todo
                            }
                        }
                    }
                }

                &.is-flipped .choices__list--dropdown {
                    top: 120%;
                    bottom: auto;
                }
                .choices__button:after {
                    content: '\\e904';
                    font-family: ${sassVars.fontFamilyGlyphIcons};
                    font-size: ${sassVars.selectChoicesButtonIconSize};
                    position: absolute;
                    left: 0;
                    top: 0;
                    line-height: 1;
                    height: ${sassVars.selectChoicesButtonHeight};
                    width: ${sassVars.selectChoicesButtonWidth};
                    color: ${theme.palette.primary.main};
                    text-indent: 0;
                    transition: ${sassVars.selectIconArrowTransitionColor};
                }

                .choices__list--single {
                    padding-right: ${sassVars.selectSinglePaddingRight};
                    padding-top: ${select.selectChoicesList.paddingTop}px;
                }

                .choices__list--dropdown {
                    background-color: ${sassVars.selectDropdownBackgroundColor};
                    border: none;
                    top: 120%;
                    box-shadow: ${select.selectDropdown.boxShadow};
                    margin-top: ${sassVars.selectDropdownMarginTop};
                    border-radius: ${select.selectDropdown.borderRadius}px;

                    .choices__input--cloned {
                        padding: ${sassVars.selectDropdownSearchPadding};
                        font-size: ${sassVars.selectDropdownSearchFontSize};
                        color: ${sassVars.selectDropdownSearchColor};
                    }

                    .choices__list {
                        padding: ${sassVars.selectListPadding};
                    }

                    .choices__item {
                        font-size: ${select.selectItem.fontSize}px;
                        color: ${palette.main};

                        padding: ${select.selectItem.padding};
                        margin-bottom: ${sassVars.selectListItemMarginBottom};
                    }

                    .choices__item--selectable.is-highlighted {
                        background-color: ${theme.variables.palette.hoverInLists};
                        color: ${theme.variables.palette.accentContrast};
                    }
                }
            }
        }

        .choices__list--dropdown .choices__list {
            max-height: 135px;
        }

        .formio-select-max-height-150 .choices__list--dropdown {
            .choices__list {
                max-height: 150px !important;
            }
        }

        .formio-select-max-height-200 .choices__list--dropdown {
            .choices__list {
                max-height: 200px !important;
            }
        }
    `;
}
