import { action, observable } from 'mobx';
// @ts-ignore
import { checkCondition } from 'formiojs/utils/utils';
import { ISidebarItem, ISidebarSubItem } from '../types/sidebar';
import { ComponentOptions, ValidateOptions } from '../types/formio';

export default class FormioSidebarStore {
    @observable items: Record<string, ISidebarItem[]> = {};

    @action.bound
    initSidebarItems(objectId: string, form: any): void {
        let formioForm: any;
        if (typeof form === 'function') {
            formioForm = form();
        } else {
            formioForm = form;
        }
        const formData = formioForm.data;
        const sidebarItems: ISidebarItem[] = [];
        formioForm.component.components.forEach((parent: ComponentOptions<any, any>) => {
            if (parent.title) {
                const parentId = parent.id;
                const sidebarItem: ISidebarItem = {
                    id: parentId,
                    title: parent.title,
                    component: parent,
                    isShown: checkCondition(parent, formData, formData),
                    hasError: false,
                    subItems: [],
                    errorsCount: 0,
                } as ISidebarItem;

                if (Array.isArray(parent.components)) {
                    const subItems: ISidebarSubItem[] = [];
                    parent.components.forEach((child) => {
                        if (child.title) {
                            subItems.push({
                                id: child.id,
                                title: child.title,
                                component: child,
                                isShown: checkCondition(child, formData, formData),
                                hasError: false,
                                parentId: parentId,
                            } as ISidebarSubItem);
                        }
                    });

                    sidebarItem.subItems = subItems;
                }

                sidebarItems.push(sidebarItem);
            }
        });
        this.items[objectId] = sidebarItems;
        this.items = { ...this.items };
    }

    @action.bound
    updateItemsVisibility(objectId: string, form: any): ISidebarItem[] {
        const result = this.items[objectId].map((item: ISidebarItem) => {
            return {
                ...item,
                isShown: checkCondition(item.component, form, form),
                subItems: item.subItems.map((subItem) => {
                    return {
                        ...subItem,
                        isShown: checkCondition(subItem.component, form, form),
                    };
                }),
            };
        });
        this.items[objectId] = [...result];
        return result;
    }

    @action.bound
    updateSidebarErrors(objectId: string, errors: any[] = []): ISidebarItem[] {
        const itemsCopy: ISidebarItem[] = JSON.parse(JSON.stringify(this.items[objectId]));
        const errorsCopy: any[] = JSON.parse(JSON.stringify(errors));

        itemsCopy.forEach((item) => {
            item.hasError = false;
            item.errorsCount = 0;

            if (item.subItems.length > 0) {
                item.subItems.forEach((subItem) => {
                    subItem.hasError = false;
                    subItem.errorsCount = 0;
                    if (subItem.component.components && subItem.component.components.length > 0) {
                        this.findErrorInComponentsRecursive(subItem.component.components, errorsCopy, subItem, item);
                    }
                });
            } else {
                this.findErrorInComponentsRecursive(item.component.components, errorsCopy, {} as ISidebarSubItem, item);
            }
        });

        this.items[objectId] = itemsCopy;
        this.items = { ...this.items };
        return itemsCopy;
    }

    private findErrorInComponentsRecursive(
        components: Array<ComponentOptions<any, ValidateOptions>> = [],
        errors: any[],
        subItem: ISidebarSubItem,
        item: ISidebarItem,
    ) {
        const componentsLength = components.length;
        for (let i = 0; i < componentsLength; i++) {
            const component = components[i];
            const componentId = component.id;

            if (componentId !== undefined) {
                const errorIndex = errors.findIndex((errorComp) => errorComp.component.id === componentId);

                if (errorIndex !== -1) {
                    item.hasError = true;
                    item.errorsCount++;
                    subItem.hasError = true;
                    subItem.errorsCount++;
                    // удаляем все элементы до и включая найденный,
                    // т.к элементы отсортированны по их встречаемости (для ускорения поиска)
                    errors.splice(0, errorIndex + 1);
                }
            }

            // Сам компонент в списке ошибок не найден, ищем дальше в его дочерних компонентах
            if (component.components && component.components.length) {
                this.findErrorInComponentsRecursive(component.components, errors, subItem, item);
            } else if (component.columns && component.columns.length) {
                // особый компонент, дочерние элементы которого называются columns
                this.findErrorInComponentsRecursive(component.columns, errors, subItem, item);
            } else if (component.rows && component.rows.length) {
                // компонент таблица, дочерние элементы которого называются rows
                for (let j = 0; j < component.rows.length; j++) {
                    this.findErrorInComponentsRecursive(component.rows[j], errors, subItem, item);
                }
            }
        }
    }
}
