import { Submission } from 'formiojs/WebForm';
import { action, observable } from 'mobx';
import { defaultFormsLocale } from '../constants/intl';

export interface FormDTO {
    form: object;
    submission?: FullSubmission;
    i18n: object;
}

export type FullSubmission = Record<string, Submission>;

export class FormModel {
    @observable.ref form: object = {};
    private submission?: FullSubmission;
    private startSubmission?: FullSubmission;
    @observable.ref i18n: object = {};
    @observable loaded = false;

    private readonly ownerEntityId: string;

    constructor(ownerEntityId: string) {
        this.ownerEntityId = ownerEntityId;
    }

    @action.bound
    load(dto: FormDTO): FormModel {
        this.form = dto.form;
        this.i18n = dto.i18n;
        if (dto.submission) {
            this.submission = dto.submission;
            this.startSubmission = this.clone(this.submission);
        }
        this.loaded = true;
        return this;
    }

    @action.bound
    getFullSubmission(): FullSubmission {
        if (this.submission) {
            return this.clone(this.submission);
        }
        return {};
    }

    @action.bound
    setLangSubmission(lang: string, submission: Submission): void {
        if (!this.submission) {
            this.submission = {};
        }
        this.submission[lang] = this.clone(submission);
    }

    @action.bound
    setStartSubmission(lang?: string): void {
        if (lang && this.startSubmission && this.submission) {
            this.startSubmission[lang] = this.clone(this.submission[lang]);
        } else {
            this.startSubmission = this.clone(this.submission);
        }
    }

    @action.bound
    setSubmissionFromStartSubmission(): void {
        this.submission = this.clone(this.startSubmission);
    }

    @action.bound
    getLangSubmission(lang: string): Submission {
        let langSubmission = this.emptySubmission();
        if (this.submission && this.submission[lang]) {
            langSubmission = this.clone(this.submission[lang]);
            Object.assign(langSubmission.data, { ownerEntityId: this.ownerEntityId });
        }
        return langSubmission;
    }

    @action.bound
    getSubmission(lang?: string): Submission {
        let submission;
        if (lang) {
            submission = this.getLangSubmission(lang) || this.getLangSubmission(defaultFormsLocale);
        } else {
            submission = this.getLangSubmission(defaultFormsLocale);
        }
        return submission || this.emptySubmission();
    }

    @action.bound
    emptySubmission(): Submission {
        return { data: { ownerEntityId: this.ownerEntityId }, meta: {} };
    }

    @action.bound
    clone(obj: any): any {
        return JSON.parse(JSON.stringify(obj));
    }
}
